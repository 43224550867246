import React from 'react';
import Layout from '../components/Layout';

const Gallery = () => {
  return(
    <Layout>
      <h1>GALLERY</h1>
    </Layout>
  )
}

export default Gallery;